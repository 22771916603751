<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" :lessonNum="11"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "qiú",
          hanzi: "球",
          english: "ball",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-11-qiu-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "wán",
          hanzi: "玩",
          english: "to play",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-11-wan-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "ne",
          hanzi: "呢",
          english: "(a modal particle)",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-11-ne-bubble.svg`),
          blue: true,
        },
        {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "diànshì",
          hanzi: "电视",
          english: "TV",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-11-dianshi-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "...,...ne？",
          hanzi: "句型……,……呢？",
          english: "(a modal particle)",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-11-dianshi-bubble.svg`),
          longtext: true,
          pinyinWrap: true,
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
