<template>
  <div class="game-container">
    <ChoosePicBySentenceGame
      :bgImg="bgImg"
      :picList="picList"
      :sentenceList="sentenceList"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import ChoosePicBySentenceGame from "@/components/Course/GamePage/ChoosePicBySentenceGame";
export default {
  data() {
    return {
      titleInfo:{
        hanzi:"谁的个子高?",
        pinyin:"Shéi de gèzi gāo?"
      },
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-38/background-board.svg"),
      picList: [
        {
          id: 1,
          menuImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-1.svg"),
          menuGrayImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-1-bw.svg"),
        },
        {
          id: 2,
          menuImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-2.svg"),
          menuGrayImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-2-bw.svg"),
        },
        {
          id: 3,
          menuImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-3.svg"),
          menuGrayImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-3-bw.svg"),
        },
      ],

      sentenceList: [
        {
          id: 1,
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-38/image-1.svg"),
          hanzi: "狗的个子高。",
          pinyin: "Gǒu de gèzi gāo.",
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-38/image-2.svg"),
          hanzi: "鸟的个子高。",
          pinyin: "Niǎo de gèzi gāo.",
        },
        {
          id: 3,
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-38/image-3.svg"),
          hanzi: "爸爸的个子高。",
          pinyin: "Bàba de gèzi gāo.",
        },
      ],
    };
  },
  components: {
    ChoosePicBySentenceGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











