<template>
  <div class="singpage-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage :singList="singList" :songInfo="songInfo" :timeStampList="timeStampList" :isReviewPage="isReviewPage"></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-75.mp3"),
      },
      timeStampList: [7.7, 11.7, 15.6, 19.1, 31.7 ],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "wán qiú,wán qiú,zǎoshang wán qiú,",
            chineseWords: "玩球，玩球，早上玩球",
          },
          {
            id: 2,
            spelling: "wǒmen zǎoshang wán qiú,xiàwǔ ne ？",
            chineseWords: "我们早上玩球，下午呢？",
          },
          {
            id: 3,
            spelling: "Xiàwǔ,xiàwǔ,xiàwǔ kàn diànshì.",
            chineseWords: "下午、下午，下午看电视。",
          },
          {
            id: 4,
            spelling: "Wǒmen xiàwǔ kàn diànshì,wǎnshang ne？",
            chineseWords: "我们下午看电视，晚上呢？",
          },
          {
            id: 5,
            spelling: "Wǎnshang,wǎnshang ,Wǒmen wǎnshang yào shuìjiào lā！",
            chineseWords: "晚上、晚上，我们晚上要睡觉啦！",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
