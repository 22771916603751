<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "我会认",
          isUse: false,
          content: [
            {
              pinyin: "qiú",
              hanzi: "球",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "ne",
              hanzi: "呢",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "wán",
              hanzi: "玩",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "diàn shì",
              hanzi: "电视",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },
            // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
          ],
        },
        {
          title: "我会用",
          isUse: true,
          contentList: [
            
              // {
              //   pinyin: "nǐ",
              //   hanzi: "你",
              //   symbol: "",
              //   id: 1,
              //   symbolType: 0,
              // },

              // symbolType: 0word 1、 2： 3 “ 4！ 5…… 6。7”

             
            [
              {
                pinyin: "jù xíng",
                hanzi: "句型",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "“",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 3,
              },
               {
                pinyin: "\xa0",
                hanzi: "......",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
               {
              pinyin: "",
              hanzi: "",
              symbol: ",",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
                pinyin: "\xa0",
                hanzi: "......",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "ne",
                hanzi: "呢？",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "”",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 3,
              },
              
               {
                pinyin: "jìn xíng tí wèn",
                hanzi: "进行提问",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
            ],
            [
              {
                pinyin: "rú",
                hanzi: "如",
                symbol: "",
                group: 1,
                line: 2,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 2,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "wǒ xǐ huān wán qiú",
                hanzi: "我喜欢玩球",
                symbol: "",
                group: 1,
                line: 2,
                index: 2,
                symbolType: 0,
              },
              {
              pinyin: "",
              hanzi: "",
              symbol: "，",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
                pinyin: "nǐ ne",
                hanzi: "你呢",
                symbol: "",
                group: 1,
                line: 2,
                index: 2,
                symbolType: 0,
              },
              {
              pinyin: "",
              hanzi: "",
              symbol: "？",
              group: 0,
              index: 3,
              symbolType: 7,
            },
            ],
          ],
        },
        {
          title: "我会说",
          isUse: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "wǒ",
              hanzi: "我",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "huì yòng",
              hanzi: "会用",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "zhōng wén",
              hanzi: "中文",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "jiǎn dān de",
              hanzi: "简单地",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "miáo shù",
              hanzi: "描述",
              symbol: "",
              group: 2,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "mǒu gè",
              hanzi: "某个",
              symbol: "",
              group: 2,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin: "shí duàn",
              hanzi: "时段",
              symbol: "",
              group: 2,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin: "suǒ zuò de",
              hanzi: "所做的",
              symbol: "",
              group: 2,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin: "shì qíng",
              hanzi: "事情",
              symbol: "",
              group: 2,
              index: 6,
              symbolType: 0,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>